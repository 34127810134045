/* ==================================================================
    TYPOGRAPHY
================================================================== */
h1, h2, h3, h4, h5, h6 {
    color: var(--color-text);
    line-height: 1.2;
    margin-bottom: calc(0.75 * var(--spacing-unit));
    margin-top: 0;
}

h1 {
    padding-bottom: calc(0.5 * var(--spacing-unit));
    margin-top: calc(1.5 * var(--spacing-unit));
    margin-bottom: calc(1 * var(--spacing-unit));
    font-size: 2rem;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

p {
    margin: 0 0 calc(1 * var(--spacing-unit));
}

a {
    text-decoration: none;
    color: var(--color-link);
    transition: color var(--transition-duration);
}

a:hover {
    color: var(--color-link-hover);
}