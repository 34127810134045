/* ==================================================================
    HERO SECTION
================================================================== */
.hero-banner {
    position: relative;
    min-height: 70vh; /* Adjusted to 80vh */
    background: var(--color-hero-background);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    color: var(--color-text);
    padding-top: var(--navbar-height);
    transition: background var(--transition-duration), color var(--transition-duration);
    z-index: 1;
}

.hero-banner .content {
    position: relative;
    z-index: 2;
    padding: calc(2 * var(--spacing-unit)) calc(1 * var(--spacing-unit));
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-banner img {
    width: 100%;
    max-width: 600px;
    height: auto;
    margin-bottom: calc(1 * var(--spacing-unit));
    /* Prevent layout shift */
    aspect-ratio: auto;
    /* Optimize rendering */
    transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
}

.logo-text {
    font-family: Arial, sans-serif;
    font-size: 2.5rem;
    color: var(--color-logo-text);
    text-align: center;
    margin-top: -2%;
}

.mission-statement {
    font-family: Arial, sans-serif;
    font-size: 1.25rem;
    color: var(--color-logo-text);
    text-align: center;
    margin-top: -1%;
}

.hero-banner .btn {
    margin-top: calc(2 * var(--spacing-unit));
}

/* Ensure content doesn't overflow */
@media (max-height: 700px) {
    .hero-banner .content {
        padding-top: calc(1 * var(--spacing-unit));
        padding-bottom: calc(1 * var(--spacing-unit));
    }

    .logo-text {
        font-size: 2rem;
    }

    .mission-statement {
        font-size: 1rem;
    }

    .hero-banner .btn {
        margin-top: calc(1 * var(--spacing-unit));
    }
}