.cookie-consent {
  position: fixed;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: var(--color-background);
  padding: calc(var(--spacing-unit) * 2.5);
  z-index: 1000;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  width: 90%;
  max-width: 500px;
  border: 1px solid rgba(var(--color-text-rgb), 0.1);
}

.cookie-content h2 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: calc(var(--spacing-unit) * 2);
  color: var(--color-text);
  font-weight: 600;
}

.cookie-description p {
  color: var(--color-text);
  opacity: 0.9;
  text-align: center;
  margin-bottom: calc(var(--spacing-unit) * 2);
}

.cookie-types {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing-unit) * 1.5);
}

.cookie-type {
  padding: calc(var(--spacing-unit) * 1.5);
  background: rgba(var(--color-text-rgb), 0.02);
  border-radius: 12px;
  border: 1px solid rgba(var(--color-text-rgb), 0.08);
  transition: all 0.3s ease;
}

.cookie-type:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-color: rgba(var(--color-text-rgb), 0.15);
}

.cookie-type-header {
  display: flex;
  gap: calc(var(--spacing-unit) * 1.5);
  align-items: flex-start;
}

.cookie-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cookie-icon.essential {
  background: var(--color-link);
  color: white;
}

.cookie-icon.analytics {
  background: rgba(var(--color-text-rgb), 0.1);
  color: var(--color-text);
}

.cookie-info h3 {
  font-size: 1.1rem;
  margin-bottom: calc(var(--spacing-unit) * 0.5);
  color: var(--color-text);
  font-weight: 500;
}

.cookie-info p {
  font-size: 0.9rem;
  margin: 0;
  color: var(--color-text);
  opacity: 0.7;
  text-align: left;
}

.cookie-buttons {
  display: flex;
  justify-content: center;
  gap: calc(var(--spacing-unit) * 1.5);
  margin-top: calc(var(--spacing-unit) * 2.5);
}

.cookie-buttons button {
  min-width: 160px;
  padding: calc(var(--spacing-unit)) calc(var(--spacing-unit) * 1.5);
  font-weight: 500;
  transition: all 0.3s ease;
}

.decline-button {
  color: var(--color-text) !important;
  border: 1px solid rgba(var(--color-text-rgb), 0.2) !important;
  background: transparent !important;
}

.decline-button:hover {
  background: rgba(var(--color-text-rgb), 0.05) !important;
  transform: translateY(-2px);
}

.accept-button {
  background: var(--color-link) !important;
  border: none !important;
  color: white !important;
}

.accept-button:hover {
  background: var(--color-link-hover) !important;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .cookie-consent {
    width: 95%;
    padding: calc(var(--spacing-unit) * 1.5);
  }
  
  .cookie-buttons {
    flex-direction: column-reverse;
  }
  
  .cookie-buttons button {
    width: 100%;
  }
}