:root {
    /* Colors - Light Mode (Default) */
    --color-background: #f0f8ff;
    --color-text: #003f5c;
    --color-navbar-background: #003f5c;
    --color-navbar-text: #fff;
    --color-link-hover: #ef8a47;
    --color-link: #003f5c;
    --color-service-background: #fff;
    --color-service-text: #666;
    --color-hero-background: linear-gradient(135deg, rgba(240, 248, 255, 0.9), rgba(224, 247, 255, 0.9));
    --color-logo-text: #003f5c;
    --color-placeholder: rgba(0, 63, 92, 0.7);
    --color-background-rgb: 240, 248, 255;
    --color-service-background-rgb: 255, 255, 255;

    /* Typography */
    --font-size-base: 16px;
    --font-family-primary: 'Roboto', sans-serif;

    /* Spacing */
    --spacing-unit: 1rem;

    /* Layout */
    --navbar-height: 60px;
    --footer-height: 60px;

    /* Effects */
    --transition-duration: 0.2s;
    --attractor-opacity: 0.7;
}

/* Dark Mode Theme */
body.dark-mode {
    --color-background: #1e1e1e;
    --color-text: #e0e0e0;
    --color-navbar-background: #272727;
    --color-navbar-text: #fff;
    --color-link-hover: #e76254;
    --color-link: #ff9e6d;
    --color-service-background: #2e2e2e;
    --color-service-text: #bbbbbb;
    --color-hero-background: linear-gradient(135deg, rgba(30, 30, 30, 0.9), rgba(40, 40, 40, 0.9));
    --color-logo-text: #e0e0e0;
    --color-placeholder: rgba(224, 224, 224, 0.7);
    --color-background-rgb: 30, 30, 30;
    --color-service-background-rgb: 46, 46, 46;
}

/* Base HTML Elements */
body, html {
    height: 100%;
    width: 100%;
    padding-bottom: calc(3 * var(--spacing-unit));
    font-family: var(--font-family-primary);
    color: var(--color-text);
    background-color: var(--color-background);
    margin-top: 0;
    transition: background-color var(--transition-duration), 
                color var(--transition-duration);
    scroll-behavior: smooth;
} 

/* Smooth Scrolling */
html {
    scroll-behavior: smooth;
}

.skip-link {
    position: absolute;
    top: -40px;
    left: 0;
    background: var(--color-navbar-background);
    color: var(--color-navbar-text);
    padding: 8px;
    z-index: 100;
    transition: top 0.3s;
}

.skip-link:focus {
    top: 0;
}

/* Improve focus visibility */
:focus {
    outline: 3px solid var(--color-link-hover);
    outline-offset: 2px;
}

/* Hide elements visually but keep them available to screen readers */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
