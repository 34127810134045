/* Service Page Layout */
.service-page {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    background: var(--color-background);
}

/* Service Hero Section */
.service-page-hero {
    min-height: 40vh;
    background: linear-gradient(135deg, var(--color-hero-background) 0%, var(--color-service-background) 100%);
    display: flex;
    align-items: center;
    padding: calc(var(--navbar-height) + var(--spacing-unit)) 0;
    border-bottom: 1px solid rgba(var(--color-text-rgb), 0.1);
}

.service-hero-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 0 var(--spacing-unit);
}

.service-hero-icon {
    width: 100px;
    height: 100px;
    margin-bottom: calc(1.5 * var(--spacing-unit));
    animation: floatIcon 6s ease-in-out infinite;
}

.service-hero-content h1 {
    font-size: 3.6rem;
    margin-bottom: calc(2 * var(--spacing-unit));
    background: linear-gradient(135deg, var(--color-text) 0%, var(--color-link) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: -0.02em;
    position: relative;
    text-align: center;
}

/* Service Intro Section */
.service-intro {
    padding: calc(5 * var(--spacing-unit)) 0;
    position: relative;
    overflow: hidden;
    background: var(--color-background);
}

.service-intro::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, 
        rgba(var(--color-background-rgb), 0.97), 
        rgba(var(--color-service-background-rgb), 0.95)
    );
    backdrop-filter: blur(10px);
    z-index: 1;
}

.service-intro-content {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: calc(2 * var(--spacing-unit));
    align-items: start;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 var(--spacing-unit);
}

.service-intro-text {
    padding-right: calc(2 * var(--spacing-unit));
}

.service-intro-text h2 {
    font-size: 2.8rem;
    margin-bottom: calc(1.5 * var(--spacing-unit));
    background: linear-gradient(135deg, 
        var(--color-text) 0%, 
        var(--color-link-hover) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.1;
    font-weight: 800;
    letter-spacing: -0.02em;
}

.service-intro-text p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
    opacity: 0.9;
    font-weight: 400;
}

/* Capabilities Grid */
.capabilities-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: calc(1.5 * var(--spacing-unit));
}

.capability-card {
    background: rgba(var(--color-background-rgb), 0.7);
    padding: calc(1.5 * var(--spacing-unit));
    border-radius: 16px;
    border: 1px solid rgba(var(--color-text-rgb), 0.1);
    transition: all 0.3s ease;
    backdrop-filter: blur(8px);
    height: 100%;
}

.capability-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(to bottom, 
        var(--color-link-hover), 
        var(--color-text)
    );
    opacity: 0.7;
    transition: all 0.3s ease;
}

.capability-card:hover {
    transform: translateY(-4px) scale(1.01);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
    border-color: var(--color-link-hover);
}

.capability-card:hover::before {
    width: 6px;
    opacity: 1;
}

/* Benefits Section */
.service-benefits {
    padding: calc(3 * var(--spacing-unit)) 0;
    background: var(--color-service-background);
    position: relative;
}

.service-benefits h2 {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
    font-weight: 700;
    position: relative;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background: none;
}

.benefits-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: calc(1.25 * var(--spacing-unit));
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--spacing-unit);
}

.benefit-card {
    background: var(--color-background);
    padding: calc(1.5 * var(--spacing-unit));
    border-radius: 12px;
    border: 1px solid rgba(var(--color-text-rgb), 0.1);
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: calc(0.75 * var(--spacing-unit));
}

.benefit-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(to bottom, var(--color-link), var(--color-link-hover));
    opacity: 0.7;
    transition: all 0.3s ease;
}

.benefit-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
}

.benefit-card:hover::before {
    width: 6px;
    opacity: 1;
}

.benefit-icon {
    width: 40px;
    height: 40px;
    margin-bottom: calc(0.5 * var(--spacing-unit));
}

.benefit-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.benefit-card:hover .benefit-icon img {
    transform: scale(1.1);
}

.benefit-card h3 {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-text);
    margin: 0;
    line-height: 1.2;
}

.benefit-card p {
    font-size: 0.95rem;
    line-height: 1.5;
    color: var(--color-text);
    opacity: 0.9;
    margin: 0;
}

@media (max-width: 992px) {
    .benefits-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: calc(1 * var(--spacing-unit));
        max-width: 700px;
    }
    
    .service-benefits h2 {
        font-size: 2.2rem;
        margin-bottom: calc(2 * var(--spacing-unit));
    }
}

@media (max-width: 576px) {
    .benefits-grid {
        grid-template-columns: 1fr;
        max-width: 400px;
        gap: calc(1 * var(--spacing-unit));
    }
    
    .benefit-card {
        padding: calc(1.25 * var(--spacing-unit));
    }
    
    .service-benefits h2 {
        font-size: 2rem;
        margin-bottom: calc(1.5 * var(--spacing-unit));
    }
}

/* Implementation Steps */
.implementation-steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: calc(1.5 * var(--spacing-unit));
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 var(--spacing-unit);
}

.step-card {
    background: rgba(var(--color-service-background-rgb), 0.95);
    padding: calc(1.5 * var(--spacing-unit));
    border-radius: 12px;
    transition: all 0.3s ease;
    border: 1px solid rgba(var(--color-text-rgb), 0.1);
    backdrop-filter: blur(4px);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.step-card h3 {
    display: flex;
    align-items: flex-start;
    gap: calc(var(--spacing-unit));
    margin-bottom: calc(var(--spacing-unit));
    font-size: 1.2rem;
    line-height: 1.3;
}

.step-card p {
    margin: 0;
    line-height: 1.5;
    font-size: 0.95rem;
    color: var(--color-text);
    opacity: 0.9;
}

@media (max-width: 992px) {
    .implementation-steps {
        grid-template-columns: repeat(2, 1fr);
        gap: calc(1.25 * var(--spacing-unit));
        max-width: 700px;
    }
    
    .step-card {
        padding: calc(1.25 * var(--spacing-unit));
    }
}

@media (max-width: 576px) {
    .implementation-steps {
        grid-template-columns: 1fr;
        max-width: 400px;
        gap: calc(1 * var(--spacing-unit));
    }
    
    .step-card {
        padding: calc(1.25 * var(--spacing-unit));
    }
    
    .step-card h3 {
        font-size: 1.1rem;
        gap: calc(0.75 * var(--spacing-unit));
    }
    
    .step-number {
        width: 32px;
        height: 32px;
        min-width: 32px;
        font-size: 1rem;
    }
    
    .step-card p {
        font-size: 0.9rem;
        padding-left: calc(32px + 0.75 * var(--spacing-unit));
    }
}

/* CTA Section */
.service-cta {
    background: var(--color-service-background);
    text-align: center;
    padding: calc(1.5 * var(--spacing-unit)) 0;
    width: 100%;
    margin-bottom: 0;
}

.service-cta .Container {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 0 var(--spacing-unit);
}

.service-cta h2 {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
    font-weight: 700;
    position: relative;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background: none;
}

.service-cta p {
    font-size: 1.2rem;
    margin-bottom: calc(2 * var(--spacing-unit));
    opacity: 0.9;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    color: var(--color-text);
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
    .service-intro-content {
        grid-template-columns: 1fr 1fr;
        gap: calc(2 * var(--spacing-unit));
        padding: 0 calc(2 * var(--spacing-unit));
    }
}

@media (max-width: 992px) {
    .service-intro-content {
        grid-template-columns: 1fr;
        gap: calc(2 * var(--spacing-unit));
    }
    
    .service-intro-text {
        padding-right: 0;
        text-align: center;
        max-width: 700px;
        margin: 0 auto;
    }
    
    .capabilities-grid {
        max-width: 700px;
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .service-hero-content h1 {
        font-size: 2.8rem;
    }
    
    .service-hero-icon {
        width: 80px;
        height: 80px;
    }
    
    .service-intro-text h2 {
        font-size: 2.4rem;
    }
    
    .service-intro-text p {
        font-size: 1.2rem;
    }
    
    .service-cta {
        padding: calc(3 * var(--spacing-unit)) 0;
    }
    
    .service-cta h2 {
        font-size: 2.2rem;
    }
    
    .service-cta p {
        font-size: 1.1rem;
    }
}

@media (max-width: 576px) {
    .benefits-grid,
    .implementation-steps {
        grid-template-columns: 1fr;
    }
    
    .capabilities-grid {
        grid-template-columns: 1fr;
        gap: calc(1 * var(--spacing-unit));
    }
    
    .capability-card {
        padding: calc(1.25 * var(--spacing-unit));
    }
    
    .service-intro-content {
        padding: 0 calc(1 * var(--spacing-unit));
    }
} 

/* Service Page Layout - Additional Styles */
.service-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  background: var(--color-background);
  min-height: 100vh;
}

/* Service Sections */
.service-section,
.service-benefits,
.service-implementation,
.service-cta,
.service-intro {
  width: 100%;
  position: relative;
  z-index: 2;
  padding: calc(4 * var(--spacing-unit)) 0;
}

.service-benefits {
  background: var(--color-service-background);
}

.service-implementation {
  background: var(--color-background);
}

.service-cta {
  background: var(--color-service-background);
  padding: calc(1.5 * var(--spacing-unit)) 0;
}

/* Container */
.Container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 calc(2 * var(--spacing-unit));
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Feature List */
.feature-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: calc(1.5 * var(--spacing-unit));
  width: 100%;
  max-width: 900px;
  margin: calc(2 * var(--spacing-unit)) auto;
}

.feature-list li {
  padding: calc(1.5 * var(--spacing-unit));
  background: rgba(var(--color-service-background-rgb), 0.95);
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid rgba(var(--color-text-rgb), 0.1);
  font-size: 1.1rem;
  line-height: 1.6;
}

.feature-list li:hover {
  transform: translateX(5px);
  border-color: var(--color-link-hover);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Section Headers */
.section-content h2,
.service-implementation h2,
.service-cta h2 {
  text-align: center;
  font-size: 2.4rem;
  margin-bottom: calc(2.5 * var(--spacing-unit));
  font-weight: 600;
  position: relative;
  padding-bottom: calc(1.5 * var(--spacing-unit));
  background: linear-gradient(135deg, var(--color-text) 0%, var(--color-link-hover) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-content h2::after,
.service-implementation h2::after,
.service-cta h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(135deg, var(--color-link-hover) 0%, var(--color-text) 100%);
  border-radius: 2px;
}

/* Service Attractor Canvas */
canvas.service-attractor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
  opacity: 0.4;
  pointer-events: none;
  clip-path: none;
  bottom: 0;
}

/* Custom Button */
.custom-button {
  padding: calc(0.8 * var(--spacing-unit)) calc(2 * var(--spacing-unit));
  font-size: 1.1rem;
  font-weight: 500;
  background-color: var(--color-link) !important;
  border-color: var(--color-link) !important;
  color: #ffffff !important;
}

.custom-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background-color: var(--color-link) !important;
  border-color: var(--color-link) !important;
}

/* Footer Panel */
.footer-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--footer-height);
  background-color: var(--color-navbar-background);
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
} 

/* Step Numbers */
.step-number {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background: var(--color-link-hover);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.step-card h3 {
  display: flex;
  align-items: center;
  gap: calc(var(--spacing-unit) * 1.5);
  margin-bottom: calc(var(--spacing-unit));
}

.step-card p {
  margin: 0;
  line-height: 1.6;
  padding-left: calc(40px + var(--spacing-unit) * 1.5);
} 

/* Implementation Section */
.service-implementation {
    padding: calc(3 * var(--spacing-unit)) 0;
    background: var(--color-background);
    position: relative;
}

.service-implementation h2 {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
    font-weight: 700;
    position: relative;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background: none;
}

/* Update the general section headers but exclude benefits, implementation, and CTA */
.section-content h2 {
    text-align: center;
    font-size: 2.4rem;
    margin-bottom: calc(2.5 * var(--spacing-unit));
    font-weight: 600;
    position: relative;
    padding-bottom: calc(1.5 * var(--spacing-unit));
    background: linear-gradient(135deg, var(--color-text) 0%, var(--color-link-hover) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Add specific styling for CTA heading */
.service-cta h2 {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
    font-weight: 700;
    position: relative;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background: none;
} 