
/* ==================================================================
    FOOTER
================================================================== */
.footer {
    background-color: var(--color-navbar-background);
    color: var(--color-navbar-text);
    padding-top: calc(2 * var(--spacing-unit));
    padding-bottom: calc(2 * var(--spacing-unit));
    border-top: 1px solid hsl(0, 0%, 30%);
    position: relative;
    z-index: 100;
}

.footer .container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.footer .back-to-top {
    color: var(--color-navbar-text);
    font-weight: 500;
}

.footer .footer-icons {
    margin-bottom: calc(1 * var(--spacing-unit));
}

.footer .social-icons {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer .social-icons li {
    display: inline-block;
    margin-right: calc(1 * var(--spacing-unit));
}

.footer .social-icons li a {
    color: var(--color-navbar-text);
    font-size: 1.25rem;
    transition: color 0.3s ease-in-out;
}

.footer .social-icons li a:hover {
    color: var(--color-link-hover);
}

.footer .copyright {
    margin-top: calc(1 * var(--spacing-unit));
    font-size: 0.875rem;
    color: var(--color-navbar-text);
}

/* Responsive Footer Adjustments */
@media (max-width: 768px) {
    .footer {
        text-align: center;
    }

    .footer .social-icons {
        margin-top: calc(1 * var(--spacing-unit));
    }
}