/* ==================================================================
    SERVICES SECTION
================================================================== */
.services {
    padding: calc(4 * var(--spacing-unit)) 0;
    background: var(--color-service-background);
}

.services-grid {
    margin-top: calc(2 * var(--spacing-unit));
}

.service-column {
    margin-bottom: calc(2 * var(--spacing-unit));
}

.service-card {
    height: 100%;
    background: var(--color-background);
    border-radius: 8px;
    padding: calc(2 * var(--spacing-unit));
    transition: all var(--transition-duration);
    border: 1px solid var(--color-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: calc(1.5 * var(--spacing-unit));
}

.service-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: calc(0.5 * var(--spacing-unit));
    margin-bottom: calc(0.5 * var(--spacing-unit));
    width: 100%;
}

.service-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.service-content h3 {
    font-size: 1.5rem;
    margin-bottom: calc(1 * var(--spacing-unit));
    color: var(--color-text);
    width: 100%;
}

.service-content p {
    color: var(--color-text);
    line-height: 1.6;
    margin-bottom: 0;
    max-width: 90%;
}

.service-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(1 * var(--spacing-unit));
    border-top: 0px solid var(--color-text);
    width: 100%;
}

.service-link {
    text-decoration: none;
    color: inherit;
    display: block;
    height: 100%;
}

.service-link:hover .service-card {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
    border-color: var(--color-link-hover);
}

.service-icon {
    margin-bottom: calc(1.5 * var(--spacing-unit));
    margin-top: calc(1 * var(--spacing-unit));
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-icon img {
    width: 64px;
    height: 64px;
    transition: transform 0.3s ease;
}

.service-link:hover .service-icon img {
    transform: scale(1.1);
}

.learn-more {
    color: var(--color-link-hover);
    font-weight: 500;
    font-size: 0.9rem;
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
    display: flex; /* Use flex to align items */
    align-items: center; /* Center align items vertically */
}

.learn-more svg {
    width: 12px; /* Adjust the size of the arrow */
    height: 12px; /* Adjust the size of the arrow */
    margin-left: 5px; /* Add space between text and arrow */
}
.service-link:hover .learn-more {
    opacity: 1;
    transform: translateX(0);
}

@media (max-width: 768px) {
    .service-card {
        padding: calc(1.5 * var(--spacing-unit));
    }
    
    .service-icon img {
        width: 48px;
        height: 48px;
    }
    
    .service-content h3 {
        font-size: 1.25rem;
    }
}

.services h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
}
