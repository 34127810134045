
/* ==================================================================
    CONTACT SECTION
================================================================== */
.contact {
    padding: calc(4 * var(--spacing-unit)) 0;
    background: var(--color-service-background);
    transition: background-color var(--transition-duration), color var(--transition-duration);
}

.contact h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
}

.contact p {
    text-align: center;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
    font-size: 1rem;
}

.contact a {
    color: var(--color-link-hover);
    font-size: 1rem;
}

body.dark-mode .contact a {
    color: var(--color-link-hover);
    font-size: 1rem;
}

.contact-form {
    max-width: 100%;
    margin: 0 auto;
    padding: calc(1 * var(--spacing-unit));
    background-color: var(--color-background);
    border: 2px solid var(--color-text);
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form .form-label {
    font-weight: bold;
    color: var(--color-text);
}

.contact-form .form-control {
    border: 1px solid var(--color-text);
    border-radius: 5px;
    padding: calc(0.75 * var(--spacing-unit));
    font-size: 1rem;
    color: var(--color-text);
    background-color: transparent;
}

.contact-form .form-control::placeholder {
    color: var(--color-placeholder);
    opacity: 1; /* Ensures the color is not further faded by browsers */
}

.contact-form .form-control:focus {
    border-color: var(--color-link-hover);
    box-shadow: none;
}

.contact-form .btn {
    background-color: var(--color-navbar-background);
    border-color: var(--color-navbar-background);
    padding: calc(0.75 * var(--spacing-unit)) calc(1.5 * var(--spacing-unit));
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    color: var(--color-navbar-text);
    width: 100%;
}

.contact-form .btn:hover {
    background-color: var(--color-link-hover);
    border-color: var(--color-link-hover);
    color: var(--color-navbar-text);
}

.contact-form .mb-3 {
    width: 100%;
}

.contact-form .form-control::-webkit-input-placeholder {
    color: var(--color-placeholder);
    opacity: 1;
}

.contact-form .form-control::-moz-placeholder {
    color: var(--color-placeholder);
    opacity: 1;
}

.contact-form .form-control:-ms-input-placeholder {
    color: var(--color-placeholder);
    opacity: 1;
}

.contact-form .form-control::placeholder {
    color: var(--color-placeholder);
    opacity: 1;
}
