
/* ==================================================================
    ABOUT US SECTION
================================================================== */
.about-us {
    padding: calc(4 * var(--spacing-unit)) 0;
    background-color: var(--color-background);
    transition: background-color var(--transition-duration), color var(--transition-duration);
    color: var(--color-text);
}

.about-us h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: calc(2 * var(--spacing-unit));
    color: var(--color-text);
}

.about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1rem;
}

.about-text {
    flex: 1;
    margin-right: calc(2 * var(--spacing-unit));
    font-size: 1rem;
    line-height: 1.6;
    text-align: left; 
}

.about-text p {
    margin-bottom: calc(1.5 * var(--spacing-unit));
    color: var(--color-text);
    font-size: 1rem;
}

.about-image {
    flex: 1;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.about-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .about-content {
        flex-direction: column;
        text-align: left;
    }

    .about-text {
        margin-right: 0;
        margin-bottom: calc(2 * var(--spacing-unit));
    }

    .about-image {
        max-width: 100%;
    }
}