.page-hero {
    position: relative;
    min-height: 60vh;
    background: var(--color-hero-background);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: calc(var(--navbar-height) + var(--spacing-unit) * 4);
    padding-bottom: calc(var(--spacing-unit) * 4);
    overflow: hidden;
    border-bottom: 1px solid rgba(var(--color-text-rgb), 0.1);
  }
  
  .page-hero canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    pointer-events: none;
  }

.page-header {
    position: relative;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;
    z-index: 1;
    padding: 0 var(--spacing-unit);
  }
  
  .page-header h1 {
    font-size: 3.5rem;
    margin-bottom: calc(var(--spacing-unit) * 2);
    color: var(--color-text);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 700;
    line-height: 1.1;
  }
  
  .page-header .intro-text {
    font-size: 1.25rem;
    line-height: 1.6;
    color: var(--color-text);
    opacity: 0.9;
    max-width: 800px;
    margin: 0 auto;
  }
  
  @media (max-width: 768px) {
    .page-header h1 {
      font-size: 2.5rem;
    }
    
    .page-header .intro-text {
      font-size: 1.1rem;
    }
  }