
/* ==================================================================
    HEADER & NAVIGATION
================================================================== */
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--color-navbar-background);
    color: var(--color-navbar-text);
    z-index: 1000;
    padding: calc(0.5 * var(--spacing-unit)) calc(1 * var(--spacing-unit));
    transition: background-color var(--transition-duration), color var(--transition-duration);
    height: var(--navbar-height);
    display: flex;
    align-items: center;
}

.navbar-brand {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--color-navbar-text);
    text-decoration: none;
    display: flex;
    align-items: center;
}

.navbar-brand .logo {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
    margin-right: calc(0.5 * var(--spacing-unit));
}

.navbar-nav {
    margin-left: auto;
}

.navbar-nav .nav-item {
    margin-left: calc(1.5 * var(--spacing-unit));
}

.navbar-nav .nav-link {
    color: var(--color-navbar-text);
    text-decoration: none;
    font-size: 1rem;
}

.navbar-nav .nav-link:hover {
    color: var(--color-link-hover);
}

/* Navbar Toggler */
.navbar-toggler {
    border: none;
    background: transparent;
    color: var(--color-navbar-text);
    font-size: 1.5rem;
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media (max-width: 992px) {
    /* Mobile Navigation Menu Styles */
    .navbar-collapse {
        background-color: var(--color-navbar-background);
        padding: calc(1 * var(--spacing-unit));
        position: absolute;
        top: var(--navbar-height);
        left: 0;
        width: 100%;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    .navbar-nav {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-left: 0;
    }

    .navbar-nav .nav-item {
        margin-left: 0;
        width: 100%;
    }

    .navbar-nav .nav-link {
        padding: calc(0.75 * var(--spacing-unit)) calc(1 * var(--spacing-unit));
        width: 100%;
        color: var(--color-navbar-text);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .navbar-nav .nav-link:last-child {
        border-bottom: none;
    }

    .navbar-nav .nav-link:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    /* Adjust the toggler position */
    .navbar-toggler {
        margin-left: auto;
    }
}

/* Adjust the collapsing transition */
.navbar-collapse {
    transition: height 0.3s ease;
    overflow: hidden;
}

.navbar-collapse.collapsing {
    height: 0;
}

.navbar-collapse.show {
    height: auto;
}