.privacy-policy {
  padding: calc(var(--spacing-unit) * 4) var(--spacing-unit);
  max-width: 800px;
  margin: 0 auto;
  color: var(--color-text);
  background: var(--color-background);
}

.privacy-header {
  text-align: center;
  margin-bottom: calc(var(--spacing-unit) * 4);
  margin-top: 60px;
}

.privacy-header h1 {
  font-size: 2.8rem;
  margin-bottom: calc(var(--spacing-unit));
  background: linear-gradient(135deg, var(--color-link) 0%, var(--color-link-hover) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  letter-spacing: -0.02em;
  position: relative;
  display: inline-block;
  padding-bottom: calc(var(--spacing-unit) * 1.5);
}

.privacy-header h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 4px;
  background: linear-gradient(90deg, var(--color-link) 0%, var(--color-link-hover) 100%);
  border-radius: 2px;
}

.last-updated {
  font-size: 1rem;
  opacity: 0.7;
  margin: 0;
  color: var(--color-text);
}

.privacy-content {
  background: var(--color-background);
  border-radius: 16px;
  padding: calc(var(--spacing-unit) * 3);
  border: 1px solid rgba(var(--color-text-rgb), 0.1);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
}

.privacy-section {
  position: relative;
  padding: calc(var(--spacing-unit) * 2);
  margin-bottom: calc(var(--spacing-unit) * 2);
  border-radius: 12px;
  background: var(--color-background);
  border: 1px solid rgba(var(--color-text-rgb), 0.08);
  transition: all 0.3s ease;
}

.privacy-section:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-color: rgba(var(--color-text-rgb), 0.15);
}

.privacy-section:last-child {
  margin-bottom: 0;
}

.privacy-section h2 {
  font-size: 1.8rem;
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  color: var(--color-text);
  font-weight: 600;
}

.privacy-section h3 {
  font-size: 1.3rem;
  margin-top: calc(var(--spacing-unit) * 2);
  margin-bottom: calc(var(--spacing-unit));
  color: var(--color-text);
  font-weight: 500;
}

.privacy-section p {
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  color: var(--color-text);
  opacity: 0.9;
}

.privacy-section ul {
  margin: calc(var(--spacing-unit)) 0;
  padding-left: calc(var(--spacing-unit) * 2);
}

.privacy-section li {
  margin-bottom: calc(var(--spacing-unit) * 0.75);
  line-height: 1.6;
  color: var(--color-text);
  opacity: 0.9;
}

.privacy-section li strong {
  color: var(--color-text);
  opacity: 1;
  font-weight: 500;
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
  }

  .privacy-content {
    padding: calc(var(--spacing-unit) * 1.5);
  }

  .privacy-header h1 {
    font-size: 2.2rem;
  }

  .privacy-section h2 {
    font-size: 1.5rem;
  }

  .privacy-section h3 {
    font-size: 1.2rem;
  }
} 

/* Add this to ensure the main background extends fully */
main {
  background: var(--color-background);
} 