.terms-policy {
  padding: calc(var(--spacing-unit) * 4) var(--spacing-unit);
  max-width: 800px;
  margin: 0 auto;
  color: var(--color-text);
  background: var(--color-background);
}

.terms-header {
  text-align: center;
  margin-bottom: calc(var(--spacing-unit) * 4);
  margin-top: 60px;
}

.terms-header h1 {
  font-size: 2.8rem;
  margin-bottom: calc(var(--spacing-unit));
  background: linear-gradient(135deg, var(--color-link) 0%, var(--color-link-hover) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.last-updated {
  color: var(--color-text-secondary);
  font-size: 0.9rem;
}

.terms-section {
  margin-bottom: calc(var(--spacing-unit) * 3);
}

.terms-section h2 {
  color: var(--color-text);
  font-size: 1.8rem;
  margin-bottom: calc(var(--spacing-unit) * 1.5);
  font-weight: 600;
}

.terms-section p {
  color: var(--color-text);
  line-height: 1.6;
  margin-bottom: calc(var(--spacing-unit) * 1.5);
}

.terms-section ul {
  list-style-type: none;
  padding-left: calc(var(--spacing-unit) * 2);
  margin-bottom: calc(var(--spacing-unit) * 1.5);
}

.terms-section ul li {
  position: relative;
  margin-bottom: calc(var(--spacing-unit));
  color: var(--color-text);
}

.terms-section ul li::before {
  content: "•";
  color: var(--color-link);
  position: absolute;
  left: calc(var(--spacing-unit) * -2);
}

@media (max-width: 768px) {
  .terms-policy {
    padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
  }

  .terms-header h1 {
    font-size: 2.2rem;
  }

  .terms-section h2 {
    font-size: 1.5rem;
  }
}

/* Ensure the main background extends fully */
main {
  background: var(--color-background);
} 