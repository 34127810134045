#attractorCanvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
    opacity: var(--attractor-opacity);
    transition: opacity var(--transition-duration), left var(--transition-duration) ease-in-out;
}

@media (max-width: 768px) {
    #attractorCanvas {
        width: 100%;
        height: 100%;
        left: 0%;
        top: 0;
        opacity: 1;
    }
}

/* Contact button in canvas */

.custom-button {
    background-color: var(--color-link) !important;
    border-color: var(--color-link) !important;
    color: #ffffff !important; /* Ensuring text color is white for contrast */
  }
  
  .custom-button:hover {
    background-color: var(--color-link) !important; 
    border-color: var(--color-link) !important;
  }
  